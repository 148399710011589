import React from "react"
import { Link } from "gatsby"

const Header = ({ siteTitle, siteDescription }) => {
  return (
    <header className="flex p-4 text-center">
      <div className="mx-auto">
        <div>
          <h1>
            <Link className="no-underline hover:text-black" to="/">
              {siteTitle}
            </Link>
          </h1>
        </div>
        <div className="text-xl font-header">{siteDescription}</div>
      </div>
    </header>
  )
}

export default Header
