import React from "react"
import { Link } from "gatsby"

const SocialLink = ({ href, iconClassName }) => (
  <a href={href} target="_blank" rel="noopener noreferrer">
    <i className={`px-2 ${iconClassName}`}></i>
  </a>
)

const SocialBar = ({ className }) => (
  <div className={className}>
    <div className="text-2xl pt-1 row-start-2">
      <SocialLink
        href="https://twitter.com/RiazJahangir"
        iconClassName="fab fa-twitter"
      />
      <SocialLink
        href="https://instagram.com/AuthorRiazJahangir"
        iconClassName="fab fa-instagram"
      />
      <SocialLink
        href="https://www.facebook.com/AuthorRiazJahangir"
        iconClassName="fab fa-facebook"
      />
      <SocialLink
        href="mailto:author@riazjahangir.com"
        iconClassName="far fa-envelope"
      />
    </div>
  </div>
)

const PagesBar = ({ className, pages }) => (
  <div className={className}>
    <div className="flex font-bold">
      {pages.map(([text, page], index) => (
        <>
          <div key={text}>
            <div className="p-2">
              <Link
                to={page}
                activeClassName="active-page"
                className="no-underline"
              >
                {text}
              </Link>
            </div>
          </div>
          {index < pages.length - 1 && (
            <div className="text-xl pt-1 text-gray-900">|</div>
          )}
        </>
      ))}
    </div>
  </div>
)

const NavBar = () => {
  const pages = [
    ["SHORT FICTION", "/"],
    ["ABOUT ME", "/about"],
  ]

  return (
    <div className="w-full mt-4 px-4 font-simple grid grid-cols-1 grid-rows-2 sm:grid-cols-2 sm:grid-rows-1">
      <PagesBar className="mx-auto col-1 row-1 sm:ml-0 z-50" pages={pages} />
      <SocialBar className="mx-auto col-1 row-2 sm:col-2 sm:row-1 sm:mr-0 z-50" />
    </div>
  )
}

export default NavBar
