import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Helmet from "react-helmet"

import Header from "./Header"
import NavBar from "./NavBar"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)

  return (
    <div className="relative z-20 h-screen">
      <div className="mx-auto">
        <Helmet>
          <link rel="preconnect" href="https://fonts.gstatic.com" />
          <link
            href="https://fonts.googleapis.com/css2?family=EB+Garamond&family=Fredericka+the+Great&family=Montserrat&display=block"
            rel="stylesheet"
          />
          <script
            src="https://kit.fontawesome.com/95049940cf.js"
            crossorigin="anonymous"
          ></script>
        </Helmet>
        <Header
          siteTitle={data.site.siteMetadata?.title}
          siteDescription={data.site.siteMetadata?.description}
        />
        <NavBar />
        <main>{children}</main>

        <footer></footer>
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
